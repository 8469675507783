// stylelint-disable declaration-no-important
//
// Text
//
.touch-action-none {
  touch-action: none;
}

.text-sub {
  opacity: 0.8;
}

svg {
  .text-light &,
  .text-white & {
    fill: $white;
  }

  .text-dark & {
    fill: theme-color("dark");
  }
}

.text-body {
  color: $body-bg !important;
}

.text-dirty {
  font-style: italic;
  text-decoration: underline dotted;
}

.font-family-monospace {
  font-family: $font-family-monospace;
}

.input-group-page-count {
  min-width: 76px;
}

.input-group-page-count-link {
  min-width: 50px !important;
}

.line-height-heading {
  line-height: $headings-line-height;
}

.x-unselectable {
  cursor: default !important;
}

.not-selectable {
  @include not-selectable();
}

.img-drag-none {
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.x-menu-list {
  @extend .list-unstyled !optional;
}

::-webkit-input-placeholder,
.btn-no-focus,
.user-select-none {
  user-select: none !important;
}

.pointer-events-none {
  pointer-events: none;
  touch-action: none;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.no-focus-outline,
.x-grid3-hd {
  &,
  &:focus {
    outline: none;
  }
}

.text-secondary {
  opacity: 0.7;
  color: inherit !important;
}

.dropdown-holder-no-results {
  opacity: 0.5;
}

.text-overflow-ellipses {
  text-overflow: ellipsis;
}

.remove-grid-link,
.wrap-grid-link,
.text-link,
.gwt-Anchor,
.link {
  cursor: pointer;
  text-decoration: $link-decoration;
  color: $link-color;
}

.gwt-Anchor,
.linky {
  white-space: nowrap;
  word-break: break-word;
  overflow-wrap: break-word;
}

.font-bold {
  font-weight: bold !important;
}

.alt_text_color {
  &.x-form-modified {
    font-weight: $font-weight-bold;
  }

  &.default-value {
    color: theme-color("primary") !important;
  }
}

.text-shadow {
  text-shadow: $default-text-shadow;
}

.text-modified {
  font-weight: $font-weight-bold;
}

.boldImportant,
.standard-bold,
.bold {
  font-weight: $font-weight-bold;
}

.root-font {
  font-size: 15px !important;
  font-weight: 700;
  color: theme-color("primary");
}

.modified,
.warning-text {
  color: $form-feedback-invalid-color;
}

.fileActionQueuePanelStatusCodeUnassigned {
  color: $blue;
}

.fileActionQueuePanelStatusCodeCompleted {
  color: $black;
}

.text-underline {
  text-decoration: underline;
}

.header {
  text-decoration: none;
}
.card-title {
	word-wrap: break-word;
	word-break: break-all;
}

.CommentLabel,
.card-title {
  code {
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 2px 3px 1px;
    white-space: normal;
    tab-size: 4;
    color: #d72b3f !important;
    border: 1px solid #e1e1e8;
    border-radius: 3px;
    background-color: #f7f7f9;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
  }

  pre {
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
    font-size: 0.75rem;
    line-height: 1.15rem;
    display: block;
    margin: 0.5rem 0 0.2rem;
    padding: 0.5rem;
    word-wrap: break-word;
    word-break: normal;
    tab-size: 4;
    color: #333 !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #f9f9f9;
    font-variant-ligatures: none;
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-break: break-all;
  }

  blockquote {
    margin: 0.25rem 0 0.25rem 1rem;
    padding-left: 0.5rem;
    border-left: lightgray solid 4px;
  }

  q {
    margin: 0.25rem 0 0.25rem 1rem;
    padding-left: 0.5rem;
    border-left: lightgray solid 4px;
    /* Remove the quotations from the `q` block. */
    &::before,
    &::after {
      content: none;
    }
  }
}

.text-underline-hover:hover {
  text-decoration: underline;
}

.gray,
.gray-text {
  color: color("gray");
}

.textAlignLeft {
  text-align: left;
}

.fileActionQueuePanelStatusCodeError,
.SlideViewWidgetConfigPanel-error {
  color: $red;
}

.white-space-pre {
  white-space: pre;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.white-space-normal,
.normalWhitespace {
  white-space: normal !important;
}

.word_wrapping {
  white-space: pre-wrap !important;
  word-wrap: break-word;
}

.respect_whitespace {
  white-space: pre-wrap !important;
}

.clickable,
.cursor-pointer,
.pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.cursor-move {
  cursor: move;
}

.word-break-break-word {
  word-break: break-word;
  white-space: normal;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.letter-spacing-sm {
  letter-spacing: -0.03rem;
}

.Capitalize {
  @extend .text-capitalize !optional;
}

.font-size-90-pct {
  font-size: 90%;
}

.small_font,
.font-size-sm {
  font-size: $default-font-size-sm;
}

.font-size-base {
  font-size: $font-size-base;
}

.EditableFileLabel {
  word-wrap: break-word;
  word-break: break-all;
}

.notBold {
  @extend .font-weight-normal !optional;
}

.MoreBold,
.font-weight-500 {
  font-weight: bold;
  font-weight: 500;
}

.DFHContent {
  &.standard_font {
    @extend .font-weight-500 !optional;
  }
}

.cl-ad-cuepoint {
  color: $black !important;
}

.text-hide {
  // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
  font: 0/0 a;
  color: transparent;
  border: 0;
  background-color: transparent;
  text-shadow: none;
}

.text-green-a700 {
  color: $md-green-a700;
}

.text-gray-850 {
  color: $md-gray-850;
  fill: $md-gray-850;
}
.text-color-change {
  animation: colorchange 20s infinite alternate;
}
.thicker-border {
  border-right: 2px solid #AAA;
}

.border-solid {
  border: 2px solid !important;
  border-color: #4177f6!important;
}
.rounded-icon {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.badge-filename{
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.restrictedasset-username{
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius:5px;
}
.restrictedasset-desc-overflow-clamp {
   width: 400px;
  /* this code clamps based on specified lines */
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  border-radius:5px;
  max-height:88px;
}
@keyframes colorchange {
  0% {
    color: $md-purple-500;
  }
  10% {
    color: $md-deep-purple-500;
  }
  20% {
    color: $md-indigo-500;
  }
  30% {
    color: $md-blue-500;
  }
  40% {
    color: $md-light-blue-500;
  }
  50% {
    color: $md-cyan-500;
  }
  60% {
    color: $md-teal-500;
  }
  70% {
    color: $md-green-500;
  }
  80% {
    color: $md-light-green-500;
  }
  90% {
    color: $md-lime-500;
  }
  100% {
    color: $md-orange-500;
  }
}
